export const BonusType = {
  Debit: "debit",
  Credit: "credit",
};

export const initialValue = [
  { id: 10, name: "image", status: "", img: "/images/x.jpg" },
  { id: 11, name: "image", status: "", img: "/images/x.jpg" },
  { id: 2, name: "image", status: "", img: "/images/bomb.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
].sort(() => Math.random() - 0.5);
