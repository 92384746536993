import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URI;
const BITTRIX_URL = `https://etake.bitrix24.com/rest/1085/whh6mabvb1a3ky2j/tasks.task.add.Json`;

const makeRequest = async (server_url, endpoint, data, parametr) => {
  try {
    const response = await axios.post(
      "https://scripts.cloudmallbot.online/request-etake.php",
      {
        server_url,
        endpoint: endpoint,
        data: data,
        parametr: parametr || "",
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const auth = async (payload) => {
  try {
    const response = await makeRequest(SERVER_URL, "auth", payload);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const postBonusChange = async (payload) => {
  try {
    const response = await makeRequest(SERVER_URL, "bonus-change", payload);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const getBonusProduct = async (payload) => {
  try {
    const response = await makeRequest(SERVER_URL, "prizes", payload, "get");
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const postToBittrix = async (payload) => {
  try {
    const response = await makeRequest(BITTRIX_URL, "", payload);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
