import { postBonusChange } from "../api/api";
import { bonusChange } from "../utils/mocked.api";
import Card from "./Card";
import { useState } from "react";

export default function Cards({
  setBonus,
  cards,
  games,
  attempts,
  setCards,
  setGames,
  matchCount,
  setMessage,
  setMatchCount,
  setAttempts,
  setStart,
  handleChangeBonusDebit,
  attempt,
}) {
  const [gameStarted, setGameStarted] = useState(false);

  const matchCheck = (index) => {
    if (cards[index].status === "active matched" || attempts <= 0) {
      return; // Виходимо, якщо картка вже відкрита або спроби закінчились
    }

    // Якщо гра ще не почалася, зменшуємо кількість ігор
    if (!gameStarted) {
      setGameStarted(true); // Позначаємо, що гра почалася
      const newGames = games - 1; // Зменшуємо кількість ігор
      setGames(newGames); // Оновлюємо кількість ігор
      const newAttemptData = {
        day: new Date().toISOString().split("T")[0], // Сьогоднішня дата
        attempt: newGames,
      };
      localStorage.setItem("attempt", JSON.stringify(newAttemptData)); // Зберігаємо нові дані
      setAttempts((prevAttempts) => prevAttempts - 1); // Зменшуємо кількість спроб
      setMessage(`Гра почалася!`);
    }

    const cardId = cards[index].id;
    cards[index].status = "active matched"; // Оновлюємо статус картки
    setCards([...cards]);

    // Оновлюємо кількість збігів
    const updatedMatchCount = {
      ...matchCount,
      [cardId]: (matchCount[cardId] || 0) + 1,
    };

    // Перевіряємо умови виграшу
    if (updatedMatchCount[cardId] >= 3) {
      console.log("true");
      if (cardId === 5) {
        setMessage(`Ви виграли 300 бонусів, продовжуйте грати!`);
        setBonus((prev) => prev + 300);
        handleChangeBonusDebit(
          300,
          "Нарахування 300 бонусів за виграгну комбінацію"
        );
      } else {
        setMessage(`Ви виграли 100 бонусів, продовжуйте далі!`);
        setBonus((prev) => prev + 100);
        handleChangeBonusDebit(
          100,
          "Нарахування 100 бонусів за виграгну комбінацію"
        );
      }
      setAttempts(0);
      setStart(false);
    } else {
      const newAttempts = attempts - 1; // Зменшуємо спроби
      setAttempts(newAttempts);
      if (games === 0 && newAttempts <= 0) {
        setMessage(
          "Уппс, ігри закінчились, приходьте завтра та забирайте більше бонусів"
        );
        setStart(false);
      }
      if (newAttempts <= 0) {
        setMessage("Cпроби закінчились, спробуйте ще раз");
        setStart(false); // Завершуємо гру
      }
    }

    // Обробка бомби
    if (cardId === 2) {
      if (games === 0) {
        setMessage("Уппс, ви потрапили на бомбу");
      } else setMessage("Уппс, ви потрапили на бомбу, почніть гру спочатку");
      setAttempts(0);
      setStart(false); // Зупиняємо гру
    }
    // Оновлюємо лічильник збігів
    setMatchCount(updatedMatchCount);
  };

  return (
    <div className="main">
      <div className="infoBlock">
        <div className="message">
          <span>Кількість Ігр:</span> <span className="color">{games}</span>
        </div>
        <div className="message">
          <span>Спроби:</span> <span className="color">{attempts}</span>
        </div>
      </div>
      <div className="container">
        {cards.map((card, index) => (
          <Card
            key={index}
            card={card}
            index={index}
            clickhandler={() => matchCheck(index)}
          />
        ))}
      </div>
    </div>
  );
}
